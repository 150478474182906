import React from "react";

import Usa from "../../assets/logo/usa.png";
import Canada from "../../assets/logo/canada.png";
import Nepal from "../../assets/logo/nepal.png";
import Globe from "../../assets/logo/globe.png";

export const BenefitFromSilicon = () => {
  const benefitsFromSiliconEverestData = [
    {
      id: 1,
      title: "USA",
      image: Usa,
    },
    {
      id: 2,
      title: "Canada",
      image: Canada,
    },
    {
      id: 3,
      title: "Nepal",
      image: Nepal,
    },
    {
      id: 4,
      title: "Expanding all around",
      image: Globe,
    },
  ];
  return (
    <section id="testimonial_bg" className="pb-80">
      <div className="container mx-auto py-28">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
          <div className="col-span-1 flex flex-col justify-center items-center">
            <div className="flex flex-col text-5xl font-semibold">
              <h1 className="">
                <span> Global</span>{" "}
                <span className="text-primary">Existence</span>
              </h1>
            </div>
          </div>
          <div className="col-span-1">
            <div className="grid grid-cols-2 gap-10">
              {benefitsFromSiliconEverestData.map((item, index) => (
                <div className="col-span-1 max-w-3/4" key={index}>
                  <div className="border-4 border-primary ">
                    <div className="flex flex-col justify-center items-center ">
                      <div className="h-20 w-20 ">
                        <img
                          src={item.image}
                          className="h-full w-full object-contain object-center pt-8"
                          alt="benefits"
                        />
                      </div>

                      <h1 className="font-semibold text-xl pb-4 text-center ">
                        {item.title}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
