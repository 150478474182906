import React from "react";

import Email from "../../assets/images/email.svg";
import Call from "../../assets/images/call.svg";
import Location from "../../assets/images/location.svg";
import FooterImg from "../../assets/images/footer.svg";

import { CustomButton } from "./CustomButton";

import { footerData } from "../../data/Global/Footer";
import { SocialMedia } from "./SocialMedia";
import { useNavigate } from "react-router";
import useGet from "../../api/useGet";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export const Footer = () => {
  const { data: footer } = useGet("static-home");
  const { data: setting } = useGet("site-setting");
  const navigate = useNavigate();
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
      <footer className={"bg-secondary  "}>
        <div className="relative">
          <div className="container mx-auto pt-36">
            <div className="grid lg:grid-cols-[auto,1fr] grid-cols-1 gap-24 py-12">
              <div className="col-span-1 ">
                <div className="">
                  <h1 className="text-txtprimary text-xl font-semibold">
                    Contact
                  </h1>
                  <div className="flex flex-col gap-2 pt-8">
                    <div className="flex gap-4">
                      <img src={Call} alt="call_icon" />
                      <Link
                        to={`tel:${setting?.phone}`}
                        className="text-txtsecondary hover:underline"
                      >
                        {setting?.phone}
                      </Link>
                    </div>
                    <div className="flex gap-4">
                      <img src={Email} alt="email_icon" />
                      <Link
                        to={`tel:${setting?.email}`}
                        className="text-txtsecondary hover:underline"
                      >
                        {setting?.email}
                      </Link>
                    </div>
                    <div className="flex gap-6">
                      <img src={Location} alt="location_icon" />
                      <p className="text-txtsecondary">
                        {setting?.contact_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex  justify-around w-full ">
                {footerData.map((item, index) => (
                  <div key={index}>
                    <h1 className="text-txtprimary text-lg font-semibold">
                      {item.title}
                    </h1>
                    <div className="flex flex-col gap-2 pt-6 overflow-hidden h-full">
                      {Array.from({ length: item.list.length }).map(
                        (_, index) => (
                          <p
                            key={index}
                            className="text-txtsecondary cursor-pointer hover:underline"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              navigate(item.list[index].link);
                            }}
                          >
                            {item.list[index].title}
                          </p>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="border-t w-full border-txtsecondary">
            <div className="container mx-auto ">
              <div className="flex justify-between items-center py-8">
                <h1 className="text-txtsecondary">
                  <Link to={""} className="hover:underline">
                    Terms & Conditions
                  </Link>{" "}
                  {setting?.copyright_text}
                </h1>
                <SocialMedia color={"primary"} />
              </div>
            </div>
          </div>

          <div className="container mx-auto  absolute -top-[180px] left-0 right-0">
            <div className="lg:max-w-1/2 max-w-full bg-lineColor rounded-3xl ">
              <div className="grid lg:grid-cols-2 grid-cols-1  min-h-[295px] ">
                <div className="col-span-1 self-center ">
                  <div className="pl-12">
                    <h1 className="text-3xl font-semibold text-txtprimary">
                      {footer?.footer_title}
                    </h1>
                    <div className="text-txtsecondary text-base">
                      {footer?.footer_description}
                    </div>
                    <div className="py-8">
                      <CustomButton
                        title={"Reach out to us"}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          navigate("/contact");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 h-full relative">
                  <div className="  absolute -top-1/3 right-5">
                    <img
                      src={FooterImg}
                      alt=""
                      className="max-h-[362px] lg:block hidden"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
