import React from "react";
import { HeaderWidthCalculator } from "../Global/HeaderWidthCalculator";

import CustomerCentric from "../../assets/icons/customercentric.png";
import Comprehensive from "../../assets/icons/comprehensive.png";
import Expertise from "../../assets/icons/expertise.png";
import HtmlParse from "../Global/HtmlParse";
import useGet from "../../api/useGet";
export const ChooseUsCard = ({ title, img }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="bg-primary h-44 w-3/4 flex justify-center items-center">
        <div className="h-15 w-16">
          <img
            src={img}
            className="h-full w-full object-contain object-center"
            alt=""
          />
        </div>
      </div>
      <h1 className="font-semibold p-2">{title}</h1>
    </div>
  );
};

export const ChooseUs = ({ data }) => {
  const { data: why } = useGet("whyuses");
  return (
    <section className="bg-[#D5D5D5]">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 grid-cols-1 pt-16 gap-10">
          <div className="col-span-1 lg:-order-1 order-1 lg:pb-0 pb-6">
            <div className="grid grid-cols-3 ">
              {why?.slice(0,3).map((item, index) => (
                <div
                  className={`col-span-1 ${index % 2 !== 0 && "lg:py-16"}`}
                  key={item?.id}
                >
                  <ChooseUsCard
                    title={item?.name}
                    img={item?.image}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-1 ">
            <div className=" lg:pt-10 ">
              <div className="inline-block">
                <HeaderWidthCalculator title={"Why Choose Us"} />
              </div>
              <div className="leading-7">
                <HtmlParse data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
