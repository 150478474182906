import React from "react";

import { HeaderWidthCalculator } from "../../components/Global/HeaderWidthCalculator";

import Bloodbank from "../../assets/img/BloodBank.png";
import Order from "../../assets/img/mobileRestro.png";
import { Pc } from "../Global/Pc";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";

export const HeroSection = () => {
  const { data } = useGet("static-products");

  return (
    <section>
      <div className="bg-secondary ">
        <div className="container mx-auto ">
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center py-12">
              <HeaderWidthCalculator title="Our Products" color={"white"} />
              <div className="text-txtsecondary lg:max-w-1/2 text-center">
                {data?.product_description && (
                  <HtmlParse data={data?.product_description} />
                )}
              </div>
            </div>
            <div className="flex justify-start lg:max-w-3/4 items-center h-[60vh]">
              <div className=" h-3/4 lg:max-w-1/2 place-self-end ">
                <img
                  src={data?.product_first_img || Order}
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </div>

              <div className="lg:block hidden h-1/2 place-self-end">
                <Pc image={data?.product_second_img ||Bloodbank} maxWidth={"20rem"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
