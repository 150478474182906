import Php from "../../assets/logo/php.svg";
import Figma from "../../assets/logo/figma.svg";
import Laravel from "../../assets/logo/laravel.svg";
import Sql from "../../assets/logo/sql.svg";
import Vs from "../../assets/logo/visualstudio.svg";
import Wordpress from "../../assets/logo/wordpress.svg";
import Dotnet from "../../assets/logo/dotnet.svg";
import css from "../../assets/logo/Css.png";
import csharp from "../../assets/logo/C#.png";
import html from "../../assets/logo/Html.png";
import Java from "../../assets/logo/Java.png";
import github from "../../assets/logo/GitHub.png";
import react from "../../assets/logo/React.png";

export const technologyWeUseList = [
  Dotnet,
  Php,
  Laravel,
  Sql,
  Vs,
  Wordpress,
  Figma,
  css,
  csharp,
  html,
  Java,
  github,
  react,
];
