import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useLayoutContext } from "../../context/LayoutContext";

import { IoMdSearch } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import "../../App.css";
import Logo from "../../assets/images/comp_logo.svg";
import { navData } from "../../data/Global/Header";
import useGet from "../../api/useGet";
import Favicon from "react-favicon";
import UsePost from "../../api/UsePost";
import Loader from "./Loader";
import SearchField from "./SearchField";

export const Header = () => {
  const { headerBg } = useLayoutContext();
  const [showNav, setShowNav] = useState(false);
  const [keyword, setKeyword] = useState("");
  const { data: setting } = useGet("site-setting");
  const { post, postData, isLoading, setPostData } = UsePost("search-product");
  const initialValues = {
    keyword: "",
  };
  const Schema = Yup.object().shape({
    keyword: Yup.string(),
  });
  const handleSearch = async (values) => {
    console.log("Search values:", values);
    await post(values, "Search");
  };

  return (
    <>
      {setting?.favicon && <Favicon url={setting?.favicon} />}

      <header className={showNav ? "h-[100vh] bg-secondary w-full" : ""}>
        <nav
          className={
            headerBg ? "bg-secondary" : "absolute top-0 left-0 right-0"
          }
        >
          <div className="container mx-auto">
            <div className="grid  2xl:grid-cols-[auto,1fr,2fr] xl:grid-cols-[auto,2fr,2fr] lg:grid-cols-[auto,1fr,2fr]  md:grid-cols-[auto,12fr,1fr]  grid-cols-[auto,1fr,auto] lg:gap-14 md:gap-24 gap-3 text-white py-4 items-center ">
              <div className="h-12 w-12 ">
                <Link to={"/"}>
                  <img
                    src={setting?.logo || Logo}
                    alt="logo"
                    className="h-full w-full object-contain object-center"
                    onClick={() => {
                      document.body.classList.remove("overflow-hidden");
                      setShowNav(false);
                    }}
                  />
                </Link>
              </div>
              <div className="form-wrapper relative">
                <Formik
                  initialValues={initialValues}
                  validationSchema={Schema}
                  onSubmit={(values) => handleSearch(values)}
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="flex  relative items-center">
                        <SearchField
                          name={"keyword"}
                          data={postData && postData}
                          setkeyword={(value) => {
                            setKeyword(value);
                            formik.setFieldValue("keyword", value);
                          }}
                          setPostData={setPostData}
                        />
                        <button className="absolute right-3" type="submit">
                          {!isLoading ? (
                            <IoMdSearch />
                          ) : (
                            <span class="search-loader"></span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className=" lg:hidden">
                <button
                  onClick={() => {
                    if (showNav) {
                      document.body.classList.remove("overflow-hidden");
                    } else {
                      document.body.classList.add("overflow-hidden");
                    }
                    setShowNav(!showNav);
                  }}
                >
                  <RxHamburgerMenu />
                </button>
              </div>
              <div className="hidden lg:flex justify-between  text-xs">
                {navData.map((item, index) => (
                  <React.Fragment key={index}>
                    <li className="2xl:text-[14px]">
                      <Link to={item.link} className="inline-block py-2 ">
                        {item.title}
                      </Link>
                    </li>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className={showNav ? "text-white lg:hidden" : "hidden"}>
              {navData.map((item, index) => (
                <React.Fragment key={index}>
                  <li className="py-4 ">
                    <Link
                      to={item.link}
                      onClick={() => {
                        document.body.classList.remove("overflow-hidden");
                        setShowNav(false);
                      }}
                    >
                      {item.title}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
