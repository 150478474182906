import React from "react";

import { HeaderWidthCalculator } from "../Global/HeaderWidthCalculator";

import { HowItWorksList } from "../../data/Home/HowItWorks";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";

export const HowItWorks = () => {
  const { data } = useGet("works");
  return (
    <section className="my-10">
      <div className="container mx-auto">
        <div className="inline-block">
          <HeaderWidthCalculator title={"How It Works"} />
        </div>

        <div className=" mx-auto py-10 grid xl:grid-cols-3 lg:grid-cols-2 max-w-[90%] gap-16 ">
          {data?.map((item, index) => (
            <div
              className=" flex bg-[#EDEDED] px-4 py-6 rounded-2xl relative"
              key={index}
            >
              <div className="bg-primary h-14 rounded-lg w-14 absolute  -left-8 top-1/3 flex justify-center items-center font-bold">
                {index + 1}
              </div>
              <div className="px-8">
                <div className="h-14 w-14">
                  <img
                    src={item?.image}
                    alt=""
                    className="h-full w-full object-contain object-center"
                  />
                </div>

                <h1 className="font-bold pt-3 mb-1">{item?.name}</h1>
                <div className="text-balance [&>p]:line-clamp-6">
                  {item?.description && <HtmlParse data={item?.description} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
