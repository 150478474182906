import React, { useEffect, useRef, useState } from "react";

export const HeaderWidthCalculator = ({ title, color }) => {
  const headerRef = useRef(null);
  const [headerwidth, setHeaderWidth] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderWidth(headerRef.current.getBoundingClientRect().width);
    }
  }, [headerRef]);

  return (
    <div className="block">
      <div className="">
        <h1
          className="text-2xl font-bold  "
          style={{ color: color }}
          ref={headerRef}
        >
          {title}
        </h1>
      </div>

      <div className="flex gap-4 py-2">
        {headerwidth && (
          <div
            className=" h-2 rounded bg-primary "
            style={{ width: headerwidth * 1.15 }}
          ></div>
        )}
        {headerwidth && (
          <div
            className=" h-2 rounded bg-primary"
            style={{ width: headerwidth * 0.15 }}
          ></div>
        )}
      </div>
    </div>
  );
};
