import Pos from "../../assets/icons/POS.png";
import Account from "../../assets/icons/AccountInventory.png";
import Hr from "../../assets/icons/HrCrm.png";
import CustomFeatures from "../../assets/icons/Custom.png";

import Erp from "../../assets/icons/Erplogo.png";
import WebDesidn from "../../assets/icons/WebDesign.png";
import Appdev from "../../assets/icons/AppDevelop.png";
import Softdev from "../../assets/icons/SoftwareDev.png";
import Webhosting from "../../assets/icons/WebHosting.png";
import Eclass from "../../assets/icons/EClass.png";

export const paraDescription = `Lorem ipsum dolor sit amet consectetur. Nunc neque sed
pharetra faucibus integer pharetra massa senectus. Magna massa
porttitor arcu lorem vivamus urna eget vulputate arcu. Et
imperdiet vitae nisi nibh platea enim proin odio. Quis
facilisis eu non aliquam eros vitae facilisi aliquet. Urna sit
egestas risus dictum eget. Ultricies scelerisque elit feugiat
amet faucibus. Vitae tristique tellus etiam quis. Rutrum
pellentesque mus diam ac ipsum interdum id nascetur. Nisl
pharetra`;
export const ourFeatures = [
  {
    title: "POS",
    description:
      "POS system with integrated payments and online ordering, we design user-friendly interfaces and secure payment gateways for seamless transactions. Our solutions optimize operational efficiency and enhance customer experience, driving business growth.",
    img: Pos,
  },
  {
    title: "Account & Inventory",
    description:
      "For account and inventory systems, we develop intuitive interfaces and robust functionalities to streamline operations and optimize inventory management. Our solutions ensure accurate financial tracking and efficient stock control, empowering businesses to thrive.",
    img: Account,
  },
  {
    title: "HR & CRM",
    description:
      "For HR & CRM systems, we create intuitive interfaces and comprehensive features to streamline employee management and customer relationship management. Our solutions enhance organizational efficiency and foster stronger client relationships, driving sustainable growth",
    img: Hr,
  },
  {
    title: "Custom Features",
    description:
      "We pride customized solutions that perfectly align with your business needs. By meticulously tailoring every aspect of the system to your specifications, we ensure seamless integration and optimal performance, empowering your business to thrive in its unique landscape.",
    img: CustomFeatures,
  },
];

export const services = [
  {
    title: "ERP Service",
    img: Erp,
  },
  {
    title: "Website Design",
    img: WebDesidn,
  },
  {
    title: `Software Development`,
    img: Softdev,
  },
  {
    title: "App Development",
    img: Appdev,
  },
  {
    title: "Web Hosting",
    img: Webhosting,
  },
  {
    title: `E-Class `,
    img: Eclass,
  },
];
