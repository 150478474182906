import Restrobyte from "../../assets/img/restrobyte.png";
import eTender from "../../assets/img/eTender.png";
import Jwellery from "../../assets/img/Jewel.PNG";
import Billing from "../../assets/img/Billing.PNG";
import Erp from "../../assets/img/ERP.PNG";

export const listOfProducts = [
  {
    id: 1,
    title: "Restrobyte",
    desc: `We are glad to inform you that RestroByte is now accessible to assist your Restaurant and Hotel management. RestroByte is easy to use and can be implemented imidiately. It is very useful for ordering, billing and managing stocks.`,
    type: "PC",
    image: Restrobyte,
    link: "products",
  },
  {
    id: 2,
    title: "J-Jewellery Software",
    desc: `We are glad to inform you that JJewellery (A Complete Jewellery Management System) is now accessible to assist jewelry stores in managing their business. Anyone with computer skills and a basic knowledge of jewelry stores may start using JJewellery in less than a day. J-Jewellery is ready to offer first-rate customer service at a fair price.`,
    type: "PC",
    image: Jwellery,
    link: "products",
  },
  {
    id: 3,
    title: "e-Tender Application",
    desc: `A point-of-sale software that's quick to learn and easy to use, offering robust features to handle sales, inventory, and customer data effortlessly. Ideal for small to medium-sized eateries.`,
    type: "PC",
    image: eTender,
    link: "products",
  },
  {
    id: 4,
    title: "Silicon POS",
    desc: `Introducing our versatile Silicon POS System with integrated payment processing and online ordering features. Seamlessly handle transactions, process payments securely, and expand your reach with our intuitive online ordering system. Revolutionize your business operations and enhance customer satisfaction with our innovative POS solution.`,
    type: "PC",
    image: Billing,
    link: "products",
  },
  {
    id: 5,
    title: "Silicon ERP",
    desc: `Introducing Silicon ERP, a unified ERP system for better operations. Manage inventory, simplify sales, securely accept payments, and obtain significant information. Integrate the HR Module, CRM Module, and any other features you want, expand easily, and assure strong security. Our creative ERP solutions will help your business prosper.`,
    type: "PC",
    image: Erp,
    link: "products",
  },
];
