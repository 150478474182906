import { RiCheckboxCircleFill } from "react-icons/ri";
import { CustomButton } from "../Global/CustomButton";
export const PackageBox = ({ name, current_price, features }) => {
  return (
    <div className="package-box bg-white ">
      <div className="package-head p-6 pb-3 border-b border-solid border-gray-400">
        <h3 className="text-[#E7B60B] text-3xl font-medium mb-2 line-clamp-1">{name}</h3>
        <p className="text-txtsecondary text-3xl font-bold">
          <b className="font-bold text-black">$ {current_price} / </b>
          month
        </p>
      </div>
      <div className="package-body p-6 ">
        <ul className="h-48 overflow-y-scroll scrollbar">
          {features.map((item) => (
            <li
              key={item?.id}
              className="relative pl-7 [&:not(:last-child)]:mb-3 text-base"
            >
              <RiCheckboxCircleFill className="absolute top-[2px] left-0 text-xl" />
              {item?.feature_title}
            </li>
          ))}
        </ul>
        <div className="py-8">
          <CustomButton
            title={"INQUIRE"}
            icon={"right-arrow"}
            trailing={true}
          />
        </div>
      </div>
    </div>
  );
};
