import React from "react";
import { ProjectCard } from "../../components/Portfolio/ProjectCard";

export const Projects = ({data}) => {
  console.log(data)
  return (
    <section>
      {data?.map((item, index) => (
        <ProjectCard {...item} index={index} />
      ))}
    </section>
  );
};
