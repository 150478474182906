import React from "react";

import { SiGoogleforms } from "react-icons/si";
import { GoArrowRight } from "react-icons/go";
export const CustomButton = ({ title, icon, trailing, onClick }) => {
  const icons = {
    "right-arrow": <GoArrowRight />,
    form: <SiGoogleforms />,
  };
  return (
    <>
      <div className="flex  ">
        <button
          className={` bg-btn p-4  flex gap-2 justify-center items-center font-bold text-black rounded text-xs`}
          onClick={onClick}
        >
          {trailing ? (
            <>
              {title.toUpperCase()}

              {icons[icon]}
            </>
          ) : (
            <>
              {icons[icon]}
              {title.toUpperCase()}
            </>
          )}
        </button>
      </div>
    </>
  );
};
