import React from "react";

import About1 from "../../assets/img/about1.jpg";
import About2 from "../../assets/img/about2.jpg";
import HtmlParse from "../Global/HtmlParse";
import { Link } from "react-router-dom";

export const HeroSection = ({ data }) => {
  const aboutSlogan = "Discover Our Legacy, Explore Our Expertise";
  const aboutDesc = ` "Silicon Everest" stands as the international arm of Onviro Tech Pvt. Ltd., based in Nepal and formerly recognized as View Nepal Pvt. Ltd. (Estd. 1999). We are distinguished as a premier technology solutions provider, excelling in website design and hosting, as well as software development. Committed to catalyzing business growth and digital transformation, our firm boasts a distinguished history of serving over 1000 esteemed clients. At Silicon Everest, we espouse a culture of excellence, offering a diverse portfolio of services that encompasses mobile app development and bespoke software solutions. Silicon Everest emerges as a steadfast ally for enterprises in pursuit of cutting-edge, high-caliber technological advancements.`;
  return (
    <section id="about_bg">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 grid-cols-1 py-12 lg:gap-20 gap-2">
          <div className="col-span-1 ">
            <div className="flex flex-col">
              <div className="">
                <h1 className=" text-primary font-bold">About Us</h1>
                <div className="text-2xl text-txtprimary font-semibold line-clamp-2">
                  {data?.title || aboutSlogan}
                </div>
              </div>

              <div className="flex gap-8 items-center py-2">
                <div className="bg-primary h-1 rounded w-[60%]"></div>
                <div className=" bg-primary h-1  rounded w-8"></div>
              </div>
            </div>
            <div className=" xl:mt-32 lg:mt-24 lg:block hidden ">
              <div className="xl:h-80 lg:h-72 xl:w-[90%] lg:w-[85%]">
                <img
                  src={data?.banner ||About2}
                  className="h-full w-full xl:rounded-[8rem] lg:rounded-full object-cover object-center"
                  alt=""
                />
              </div>
            </div>
            <div className="hidden lg:flex lg:flex-col items-end xl:p-8 lg:p-12">
              <div className="bg-secondary text-txtprimary px-6 py-2  rounded">
                <div className="flex gap-x-1">
                  <div className="w-6 h-6">
                    <img
                      src="/assets/images/icon/whatsapp_icon.svg"
                      alt="whatsapp"
                      className="h-full w-full object-contain object-center"
                    />
                  </div>

                  <div className="flex flex-col  font-semibold text-sm">
                    <h1 className="">Contact Us</h1>
                    <h1><Link to="tel:+9779851033907" className="hover:underline">+977-9851033907</Link> </h1>
                  </div>
                </div>
              </div>
              <img
                src="/assets/images/icon/arrow.svg"
                className="max-h-20"
                alt=""
              />
            </div>
          </div>
          <div className="col-span-1">
            <div className="container mx-auto flex lg:flex-col flex-col-reverse  justify-center">
              <div className="max-h-80 line-clamp-[12]">
                <div className="text-txtsecondary text-justify lg:pt-0 pt-2 ">
                {data?.description ? <HtmlParse data={data?.description}/> : aboutDesc}
                </div>
              </div>
              <div className=" h-96 pt-4 ">
                <img
                  src={data?.about_img ||About1}
                  alt=""
                  className="h-full w-full lg:rounded-bl-[8rem]  rounded object-cover object-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
