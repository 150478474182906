import React, { useEffect } from "react";
import { HeroSection } from "../components/About/HeroSection";
import { MessageFromDirectors } from "../components/About/MessageFromDirectors";
import { useLayoutContext } from "../context/LayoutContext";
import { OurTeam } from "../components/About/OurTeam";
import { BenefitFromSilicon } from "../components/About/BenefitFromSilicon";
import { ChooseUs } from "../components/About/ChooseUs";
import { MissionVision } from "../components/About/MissionVision";
import useGet from "../api/useGet";
import MetaHelmet from "../components/Global/MetaHelmet";

export const About = React.memo(() => {
  const { updateHeader, headerBg } = useLayoutContext();
  const { data: about } = useGet(`static-about`);
  const { data: meta } = useGet("seo-setting");
  // console.log(about)
  useEffect(() => {
    if (!headerBg) {
      updateHeader(true);
    }
  }, [headerBg, updateHeader]);

  return (
    <>
      <MetaHelmet
        title={`About | ${meta?.meta_title ? meta?.meta_title : 'Silicon Everest'}`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <HeroSection data={about} />
      <MissionVision data={about} />
      <ChooseUs data={about?.why_choose_us} />
      <MessageFromDirectors />
      <OurTeam />
      <BenefitFromSilicon data={about?.benefitFromSilicon} />
    </>
  );
});
