import React from "react";

import { HeaderWidthCalculator } from "../../components/Global/HeaderWidthCalculator";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";

export const HeroSection = () => {
  const { data } = useGet("static-portfolio");
  const portfolioHeroSecDesc = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi in
    quasi provident amet quas, quo voluptas, sit id eius doloremque odio
    assumenda ab hic iste ea vi`;
  return (
    <section className="bg-secondary">
      <div className="container mx-auto">
        <div className="flex flex-col justify-center items-center py-12">
          <HeaderWidthCalculator title={"View Our Projects"} color={"white"} />
          <div className="text-txtsecondary text-center lg:max-w-1/2 lg:pt-0 pt-3">
            {data?.portfolio_description && <HtmlParse data={data?.portfolio_description}/>}
          </div>
        </div>
      </div>
    </section>
  );
};
