import React, { useRef, useState } from "react";

import { CustomButton } from "../Global/CustomButton";
import Dummy from "../../assets/img/dummyAvatar.png";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";
import Loader from "../Global/Loader";
import Logo from "../../assets/images/comp_logo.svg";

export const OurTeam = () => {
  const { data, isLoading } = useGet("teams");
  const [showAllTeam, setShowAllTeam] = useState(false);
  const sectionRef = useRef(null);

  return (
    <section ref={sectionRef}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container mx-auto pt-16">
          <div className="team-group">
            <div className="grid md:grid-cols-2 xl:grid-cols-3 grid-cols-1  xl:max-w-[85%] lg:max-w-[90%] gap-10 mx-auto">
              {showAllTeam
                ? data?.map((item, index) => (
                    <OurTeamCard key={index} {...item} />
                  ))
                : data
                    ?.slice(0, 6)
                    .map((item, index) => (
                      <OurTeamCard key={index} {...item} />
                    ))}
            </div>
          </div>
          <div className="flex justify-center py-8">
            <CustomButton
              title={showAllTeam ? "Show Less" : "Show All"}
              onClick={() => {
                sectionRef.current.scrollIntoView({ behavior: "smooth" });
                setShowAllTeam(!showAllTeam);
              }}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export const OurTeamCard = (item) => {
  return (
    <div className="col-span-1 shadow-md ">
      <div className="">
        <div className="w-full h-44">
          {!item?.image ? (
            <img
              src={Dummy}
              className="h-full w-full object-contain object-center"
              alt="team-img"
            />
          ) : (
            <img
              src={item?.image}
              className="h-full w-full object-contain object-center"
              alt="team-img"
            />
          )}
        </div>

        <h1 className="font-bold bg-primary py-1 text-center capitalize">
          {item?.name}
        </h1>
        <div className="text-center ">
          <h1 className="font-bold">{item?.designation}</h1>
          <div className="px-6 pb-6 pt-4 [&>p]:line-clamp-3">
            <HtmlParse data={item?.description} />
          </div>
        </div>
      </div>
    </div>
  );
};
