import React from "react";

import { HeaderWidthCalculator } from "../../components/Global/HeaderWidthCalculator";

import RedReachHimalaya from "../../assets/img/RedReachHimalaya.svg";

export const listOfValuedClients = [
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
  RedReachHimalaya,
];

export const Clients = ({ data }) => {
  return (
    <section className="pb-80 pt-10 bg-cardColor">
      <div className="container mx-auto">
        <div className="inline-block">
          <HeaderWidthCalculator title={"Our Valued Clients"} />
        </div>
        {data && (
          <div className="grid lg:grid-cols-4 grid-cols-2  gap-8  pt-8">
            {data?.map((item, index) => (
              <div className="col-span-1  " key={index}>
                <div className="lg:h-24 h-16 w-full bg-white rounded shadow-sm flex justify-center items-center">
                  <img
                    src={item?.logo}
                    alt="client"
                    className="max-w-full max-h-full object-contain object-center p-4"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
