import React from "react";

import { CustomSection } from "../../components/Global/CustomSection";

import useGet from "../../api/useGet";

export const Domain = () => {
  const { data } = useGet("services");
  return (
    <>
      {data?.data_features?.slice(0, 2).map((item, index) => (
        <section className="mt-10">
          <CustomSection
            id={index % 2 === 0 ? "Domain" : ""}
            desc={item?.description}
            title={item?.name}
            btnTitle={index % 2 === 0 ? "Learn More" : "Explore"}
            img={item?.main_image}
          />
        </section>
      ))}
    </>
  );
};
