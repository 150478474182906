import keshav from "../../assets/img/keshav.png";
import susil from "../../assets/img/Sushil.png";
import pupanjak from "../../assets/img/phupanjuk.jpg";
import purna from "../../assets/img/purna.png";

export const testimonials = [
  {
    id: 1,
    name: "Fupanjuk Bhote",
    email:
      "Sr. Operations Officer, RHEST (Rural Health and Education service trust)",
    desc: "The eTender application has left us at RHEST, a non-profit organization, thoroughly impressed. The system is not only feature-rich and unique but also remarkably secure, particularly in how it protects bidder information",
    rating: 4,
    image: pupanjak,
  },
  {
    id: 2,
    name: "Keshav Pandey",
    email: "Managing Director, Mountain Leaders Treks And Expedition Pvt.Ltd",
    desc: "Onviro Tech created the website and hosting Mountain Leaders Website since very long.We are extremely happy and satisfied with the services they have provided.",
    rating: 5,
    image: keshav,
  },
  {
    id: 3,
    name: "Susil Pant",
    email: "CEO, Gorkha International Travel Treks & Expedition Pvt.Ltd",
    desc: "It is a great company which has done alot of web page designing for me.Their service is fast and reliable.I recommend this company to others' as well who are looking for the same. ",
    rating: 4,
    image: susil,
  },
  {
    id: 4,
    name: "Purna Shrestha",
    email: "CEO, Dailomaa MultiPurpose Pvt.Ltd",
    desc: "We were having an issue with the payment system on our website. Onvirotech was the right pick for our problem. They cleared the bug in matter of minutes.We found onvirotech very helpful, simple to understand and easy to communicate",
    rating: 4,
    image: purna,
  },
  {
    id: 5,
    name: "SD MIRACLE CONSULTANCY",
    email: "",
    desc: "Onviro is one of the best IT company available in Nepal.We have received many services from it. We SD Miracle Team warmheartly recommend this company who are seeking for Web Development, software development and related tasks.",
    rating: 4,
    image: null,
  },
  {
    id: 6,
    name: "Rudra Gyawali",
    email: "",
    desc: "Thanks to Onviro Tech's fast delivery and high-quality products, the client can develop new products much quicker. The team assisted in reaching the client's goals and objectives while staying within budget, consistently proving to be an excellent partner. Onviro Tech team developed and delivered our TU Lions Blood Bank website and mobile apps.",
    rating: 4,
    image: null,
  },
  {
    id: 7,
    name: "Mohit Paudel",
    email: "",
    desc: "Onviro Tech is the leading IT company of Nepal. It has got a good environment. The company does develops tons websites for both national and international clients. Currently the office is doing projects in Laravel and WordPress. The programmers and designers are friendly.",
    rating: 4,
    image: null,
  },
  {
    id: 8,
    name: "Bijay paneru",
    email: "",
    desc: "Onviro Tech successfully established a unique look-and-feel for the client, modernizing their branding. They also delivered a seamless and functional website with an improved UX and better tools for data collection. The team was flexible, accommodating, supportive, communicative, and caring.",
    rating: 4,
    image: null,
  },
  {
    id: 9,
    name: "Apace International Institute",
    email: "",
    desc: "View Nepal web hosting company is my IT partner of our companies who always support and provide the reliable developing services.",
    rating: 4,
    image: null,
  },
  {
    id: 10,
    name: "Novel Academy",
    email: "",
    desc: "View Nepal Web Hosting is leading web based company in Kathmandu. We, Novel Academy family feels pride to be associated with View Nepal. We appreciate your prompt response in any technological errors. Wish you good luck for your future endeavors!",
    rating: 4,
    image: null,
  },
  {
    id: 11,
    name: "Armando",
    email: "",
    desc: "I had a remarkable experience working with Onviro Tech for my store's development. From the initial consultation to the final implementation, they showcased a deep understanding of my requirements and offered valuable suggestions. They were responsive to my feedback and consistently went above and beyond to ensure my satisfaction. Thank you!",
    rating: 4,
    image: null,
  },
  {
    id: 12,
    name: "Rajendra Giri, PhD",
    email: "",
    desc: "Great Website & apps development IT solution company. I have wonderful experiences. Congratulations and best wishes.",
    rating: 4,
    image: null,
  },
];
