import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { HeaderWidthCalculator } from "./HeaderWidthCalculator";

import { technologyWeUseList } from "../../data/Home/TechnologyWeUse";

export const TechnologyWeUse = ({ sectionRef, center }) => {
  return (
    <section
      className={`${center ? "py-10 lg:py-0 lg:pt-10" : "py-10 xl:py-16"}`}
      ref={sectionRef}
    >
      <div className="container mx-auto">
        <div
          className={
            center
              ? `flex flex-col justify-center items-center`
              : `inline-block`
          }
        >
          <HeaderWidthCalculator title={"Technology We Use"} />
        </div>

        <div className="flex justify-center items-center pt-8 ">
          <div className={center ? "w-3/4" : "w-full"}>
            <Swiper
              breakpoints={{
                320: {
                  slidesPerView: center ? 3 : 4,
                },
                640: {
                  slidesPerView: 8,
                },
                768: {
                  slidesPerView: 10,
                },
                1024: {
                  slidesPerView: center ? 8 : 10,
                },
                1280: {
                  slidesPerView: center ? 10 : 14,
                },
              }}
            >
              {technologyWeUseList.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="w-12 h-12">
                    <img
                      src={item}
                      alt=""
                      className="h-full w-full object-contain object-center"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
