import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { MdOutlineStar, MdOutlineStarBorder } from "react-icons/md";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";

import { HeaderWidthCalculator } from "../Global/HeaderWidthCalculator";
import { testimonials } from "../../data/Home/Testimonials";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";

export const Testimonial = ({ data }) => {
  const { data: testimonial } = useGet("testimonials");
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleNextSlide = () => {
    if (currentSlide < testimonials.length - 3) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  return (
    <section id="testimonial_bg" className="pb-80">
      <div className="container mx-auto pt-10">
        <div className="flex flex-col items-center">
          <HeaderWidthCalculator title="Testimonials" />
          <p>{data} </p>
          <div className="w-full py-8 flex">
            <button
              className="button-prev-slide px-6 lg:block hidden"
              onClick={handlePrevSlide}
              disabled={currentSlide === 0}
            >
              <div className="">
                <MdArrowLeft
                  size={40}
                  className={
                    currentSlide === 0
                      ? "bg-gray-400 rounded-full"
                      : "bg-primary rounded-full"
                  }
                />
              </div>
            </button>
            <Swiper
              spaceBetween={50}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 2,
                },
                1280: {
                  slidesPerView: 3,
                },
              }}
              onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
              modules={[Navigation]}
              navigation={{
                nextEl: ".button-next-slide",
                prevEl: ".button-prev-slide",
              }}
            >
              {testimonial?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="bg-[#F2F2F2] flex flex-col justify-between items-center p-4 rounded-xl h-full">
                    <div className="flex flex-col items-center justify-center">
                      {!item?.image ? (
                        <div className="h-24 w-24 rounded-full bg-gray-300 flex items-center justify-center">
                          <h1 className="font-semibold text-7xl">
                            {item?.name.charAt(0)}
                          </h1>
                        </div>
                      ) : (
                        <figure className="h-24 w-24 rounded-full overflow-hidden">
                          <img
                            src={item?.image}
                            alt={item?.name}
                            className="w-full h-full object-cover object-center"
                          />
                        </figure>
                      )}
                      <div className="pt-4 flex flex-col items-center justify-center">
                        <h1 className="font-bold text-lg">{item?.name}</h1>
                        <h1 className="font-semibold text-center">
                          {item?.designation}
                        </h1>
                      </div>
                      <div className="flex flex-col justify-center items-center px-4 py-4">
                        <div className="text-justify">
                          {item?.description && (
                            <HtmlParse data={item?.description} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2 text-3xl text-yellow-500">
                      {Array.from({ length: item?.rating }, (_, i) => (
                        <MdOutlineStar key={i} />
                      ))}
                      {Array.from({ length: 5 - item?.rating }, (_, i) => (
                        <MdOutlineStarBorder key={i} />
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              className="button-next-slide px-6 lg:block hidden"
              onClick={handleNextSlide}
              disabled={currentSlide >= testimonials.length - 1}
            >
              <div
                className={
                  currentSlide >= testimonials.length - 3
                    ? "bg-gray-400 rounded-full"
                    : "bg-primary rounded-full"
                }
              >
                <MdArrowRight size={40} color="#000" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
