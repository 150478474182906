import React from "react";
import { Route, Routes } from "react-router";
import {
  Home,
  About,
  Contact,
  Domain,
  Portfolio,
  Products,
  Services,
  Webpackages,
} from "../pages";
// import { ProjectDetail } from "../components/Portfolio/ProjectDetail";

export const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/domain" element={<Domain />} />
        <Route path="/products" element={<Products />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/webpackages" element={<Webpackages />} />
      </Routes>
    </>
  );
};
