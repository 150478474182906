import React from "react";

import { Link } from "react-router-dom";

import useGet from "../../api/useGet";

export const SocialMedia = ({ color }) => {
  const { data: social } = useGet("social-links");

  return (
    <div className={`inline-flex text-${color} gap-4`}>
      {social?.map((item) => (
        <Link key={item?.id} to={item?.social_link} className="w-[26px] h-[26px]">
          <img src={item?.social_icon} alt={item?.name}  className="h-full w-full object-scale-down object-center"/>
        </Link>
      ))}
    </div>
  );
};
