import React from "react";
import { useNavigate } from "react-router";
import { MdContactMail } from "react-icons/md";
import { BiArrowToTop } from "react-icons/bi";

export const Sticky = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed  flex flex-col gap-y-3  z-10 top-1/2 right-0 ">
      <button
        className="   bg-gray-600 hover:bg-gray-500  text-txtprimary px-4 py-2 rounded-l"
        onClick={() => {
          window.scrollTo(0, 0);
          navigate("/contact");
        }}
      >
        <MdContactMail />
      </button>
      <button
        className="   bg-gray-600 hover:bg-gray-500  text-txtprimary px-4 py-2 rounded-l"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <BiArrowToTop />
      </button>
    </div>
  );
};
