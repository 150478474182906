import React from "react";

export const HeaderColor = ({ title }) => {
  const words = title.split(" ");
  const firstTwoWords = words.slice(0, 4).join(" ");
  const restOfWords = words.slice(4).join(" ");
  return (
    <div className=" text-xl  ">
      <span className="text-white">{firstTwoWords}</span>{" "}
      <span className="text-primary">{restOfWords}</span>
    </div>
  );
};
