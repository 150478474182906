import React, { useEffect, useRef, useState } from "react";

import { HeaderWidthCalculator } from "../../components/Global/HeaderWidthCalculator";
import Man from "../../assets/img/man.jpg";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";

export const MessageFromDirectors = () => {
  const { data } = useGet("managing-director-speech");
  const title = `Message From Our Managing Director`;
  const name = "Man Bahadur Roka";
  const position = "Managing Director, Onviro Tech Pvt. Ltd.";
  const [imgHeight, setImgHeight] = useState(0);

  useEffect(() => {
    if (divReference.current) {
      setImgHeight(divReference.current.getBoundingClientRect().height);
    }
  }, [imgHeight]);

  const divReference = useRef(null);
  return (
    // console.log(imgHeight),
    <section className="bg-secondary relative mb-14">
      <div className="container mx-auto ">
        <div className="grid lg:grid-cols-2 grid-cols-1 ">
          <div className="col-span-1 lg:pt-28 pt-6">
            <div className="xl:ml-52 lg:ml-20  flex items-center justify-center lg:h-[105%] lg:bg-primary lg:w-[55%] lg:relative ">
              <div
                className={`lg:absolute block -right-14 top-14 lg:h-[80%] w-[40%] lg:w-full `}
              >
                <img
                  src={data?.image ||Man}
                  alt=""
                  className={`object-cover object-center h-full w-full lg:rounded-none rounded-full`}
                />
              </div>
            </div>
          </div>
          <div className="col-span-1 lg:py-28 pt-6 pb-20">
            <div className="xl:inline-block">
              <HeaderWidthCalculator title={title} color={"white"} />
            </div>

            <div className="flex flex-col " ref={divReference}>
              <div className="text-txtprimary py-6">
                <h1>{data?.name || name}</h1>
                <h1>{data?.designation || position}</h1>
              </div>
              <div className="text-white [&>p]:mb-3">
               <HtmlParse data={data?.speech}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-24 bg-primary lg:w-1/3 w-3/4 absolute right-0 -bottom-10 flex items-center px-5">
        <h1 className="font-bold text-3xl ">Meet our Team</h1>
      </div>
    </section>
  );
};
