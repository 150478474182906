import React, { useEffect } from "react";
import { HeaderWidthCalculator } from "../components/Global/HeaderWidthCalculator";
import { PackageBox } from "../components/Global/PackageBox";

import { useLayoutContext } from "../context/LayoutContext";
import useGet from "../api/useGet";
import HtmlParse from "../components/Global/HtmlParse";
import MetaHelmet from "../components/Global/MetaHelmet";

export const packageData = [
  {
    title: "Basic",
    price: "1.4",
    list: [
      { li: "2 GB Storage" },
      { li: "Unlimited Bandwidth" },
      { li: "2 Email Accounts" },
      { li: "Unlimited Subdomain" },
      { li: "Free SSL Certificate" },
    ],
  },
  {
    title: "Advance",
    price: "5.99",
    list: [
      {
        li: "Unlimited Storage",
      },
      {
        li: "Bandwidth Unlimited",
      },
      {
        li: "Unlimited Email",
      },
      {
        li: "Addon Domain- 10",
      },
      {
        li: "WordPress Installation",
      },
    ],
  },
  {
    title: "Premium",
    price: "8.99",
    list: [
      {
        li: "Unlimited Storage",
      },
      {
        li: "Bandwidth Unlimited",
      },
      {
        li: "Unlimited Email",
      },
      {
        li: "Addon Domain- Unlimited",
      },
      {
        li: "WordPress Installation",
      },
      {
        li: "Unlimited Storage",
      },
      {
        li: "Bandwidth Unlimited",
      },
      {
        li: "Unlimited Email",
      },
      {
        li: "Addon Domain- Unlimited",
      },
      {
        li: "WordPress Installation",
      },
    ],
  },
];

export const Domain = () => {
  const { updateHeader, headerBg } = useLayoutContext();
  const { data } = useGet("static-domain");
  const { data: domainPackages } = useGet("domain-hosting");
  const { data: meta } = useGet("seo-setting");

  useEffect(() => {
    if (headerBg === false) {
      updateHeader(true);
    }
  }, [updateHeader, headerBg]);
  return (
    <>
      <MetaHelmet
        title={`Domain & Hosting | ${
          meta?.meta_title ? meta?.meta_title : "Silicon Everest"
        }`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <section className="host-page pt-20 bg-[#F5F5F5] pb-80">
        <div className="container mx-auto">
          <div className="md:flex flex-col justify-center items-center">
            <div className="block">
              <HeaderWidthCalculator title={"Get Started With Hosting"} />
            </div>
            <div className="text-center lg:w-1/2">
              {data?.domain_description && (
                <HtmlParse data={data?.domain_description} />
              )}
            </div>
          </div>
          <div className="package-group grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-14 max-w-[1200px] mx-auto pt-14">
            {domainPackages?.map((item) => (
              <div className="col-span-1" key={item?.id}>
                <PackageBox {...item}></PackageBox>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
