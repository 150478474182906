import React from "react";
import styles from "../../Pc.module.css";

export const Pc = ({ image, maxWidth }) => {
  return (
    <div style={{ position: "relative", margin: "auto", maxWidth: maxWidth }}>
      <div className={styles.pc__screen}>
        <img src={image} alt="Screen" width="1600" height="1000" />
      </div>
      <div className={styles.pc__bottom}>
        <div className={styles.pc__under}></div>
      </div>
      <div className={styles.pc__base}></div>
    </div>
  );
};
