import React from "react";
import HtmlParse from "../Global/HtmlParse";

// import Management from "../../assets/images/management.svg";

export const MissionVision = ({ data }) => {
  const missionVisionData = [
    {
      id: 1,
      title: "Our Mission",
      desc: data?.our_mission,
      image: null,
    },
    {
      id: 2,
      title: "Our Vision",
      desc: data?.our_vision,
      image: null,
    },
    {
      id: 3,
      title: "Objectives",
      desc: data?.objectives,
      image: null,
    },
    {
      id: 4,
      title: "Our Journey",
      desc: data?.our_journey,
      image: null,
    },
    {
      id: 5,
      title: "Our Commitment",
      desc: data?.our_commitment,
      image: null,
    },
  ];

  const firstTwo = [missionVisionData[0], missionVisionData[1]];
  const otherThree = [
    missionVisionData[2],
    missionVisionData[3],
    missionVisionData[4],
  ];
  return (
    <section className="my-14">
      <div className="container mx-auto">
        <div
          className="flex flex-col justify-center items-center
        "
        >
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 w-[90%] ">
            {firstTwo.map((item, index) => (
              <div className="col-span-1 " key={index}>
                <div className="w-full shadow shadow-primary flex items-start p-12 h-full">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="font-bold text-xl mb-2">{item.title}</h1>
                    <div className="text-center leading-7">
                      <HtmlParse data={item?.desc} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 pt-10 w-[90%]">
            {otherThree.map((item, index) => (
              <div className="col-span-1" key={index}>
                <div className="w-full shadow shadow-primary flex items-start p-12 h-full bg-primary">
                  <div className="flex flex-col justify-center items-center ">
                    <h1 className="font-bold text-xl mb-2">{item.title}</h1>
                    <div className="text-justify leading-7">
                      <HtmlParse data={item?.desc} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
