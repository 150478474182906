import React, { useEffect } from "react";
import { useLayoutContext } from "../context/LayoutContext";
import { HeaderWidthCalculator } from "../components/Global/HeaderWidthCalculator";
import useGet from "../api/useGet";
import HtmlParse from "../components/Global/HtmlParse";
import MetaHelmet from "../components/Global/MetaHelmet";
import Logo from "../assets/images/comp_logo.svg";

export const Services = () => {
  const { updateHeader, headerBg } = useLayoutContext();
  const { data: services } = useGet("services");
  const { data: serviceDesc } = useGet("static-service");
  const { data: meta } = useGet("seo-setting");

  // const webDevlist = ["lorem", "lorem", "lorem", "lorem"];

  useEffect(() => {
    if (headerBg === false) {
      updateHeader(true);
    }
  }, [updateHeader, headerBg]);

  return (
    <>
      <MetaHelmet
        title={`Services | ${
          meta?.meta_title ? meta?.meta_title : "Silicon Everest"
        }`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <section className="pb-80">
        <div
          className="bg-secondary
      "
        >
          <div className="container mx-auto ">
            <div className="flex flex-col items-center justify-center py-8">
              <div className="inline-block">
                <HeaderWidthCalculator title={"Our Services"} color={"white"} />
              </div>

              <div className="text-center text-txtsecondary lg:w-1/2">
                {serviceDesc?.service_description && (
                  <HtmlParse data={serviceDesc?.service_description} />
                )}
              </div>
            </div>
          </div>

          <div className="container mx-auto">
            <div className="flex justify-center py-14">
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-16 w-1/2">
                {services?.data_status?.map((item, index) => (
                  <div className="col-span-1" key={index}>
                    <div className="bg-lineColor flex flex-col justify-center items-center rounded relative text-center">
                      <div className="rounded-full bg-lineColor h-20 w-20 flex justify-center items-center absolute  -top-1/4 ">
                        <div className=" h-10 w-10 ">
                          <img
                            src={item?.icon || Logo}
                            className="w-full h-full object-contain object-center "
                            alt=""
                          />
                        </div>
                      </div>
                      <p className="text-txtprimary pt-20 pb-4 font-semibold">
                        {item?.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1000px] mx-auto py-10">
          {services?.data_status?.map((item, index) => (
            <div
              className="grid lg:grid-cols-2  grid-cols-1 gap-16 [&:not(:last-child)]:mb-14"
              key={item?.id}
            >
              <div
                className={`col-span-1 py-16 ${index % 2 === 0 && "order-1"}`}
              >
                <div className="">
                  <h1 className="text-2xl font-semibold">{item?.name}</h1>
                  <div className="text-justify pt-4">
                    {item?.description && (
                      <HtmlParse data={item?.description} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <figure className="h-[400px]">
                  <img
                    src={item?.main_image || Logo}
                    alt={item?.name}
                    className="w-full h-full object-cover object-center"
                  />
                </figure>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
