import React from "react";

import { CustomButton } from "./CustomButton";
import HtmlParse from "./HtmlParse";

export const CustomSection = ({ title, desc, img, btnTitle, id }) => {
  return (
    <div
      className={
        id === "Domain"
          ? "container mx-auto bg-secondary  rounded "
          : "container mx-auto bg-cardColor rounded "
      }
    >
      <div className="grid lg:grid-cols-2 grid-cols-1">
        <div className=" col-span-1 lg:min-h-[372px] ">
          <div className=" flex flex-col px-12 justify-between py-12 h-full">
            <div className="">
              <h1
                className={
                  id === "Domain"
                    ? "text-white text-2xl font-bold "
                    : "text-2xl font-bold "
                }
              >
                {title}
              </h1>
              <div
                className={`${
                  id === "Domain"
                    ? "text-txtsecondary    lg:w-[85%] text-justify  pt-2 pb-4"
                    : " lg:w-[85%] text-justify pt-2"
                } [&>p]:line-clamp-6` }
              >
                {desc && <HtmlParse data={desc} />}
              </div>
            </div>

            <div className="py-3 ">
              <CustomButton
                title={btnTitle}
                icon={"right-arrow"}
                trailing={true}
              />
            </div>
          </div>
        </div>

        <div className="col-span-1 hidden lg:block self-center ">
          <figure className="h-[300px]">
            <img
              src={img}
              alt={title}
              className="h-full w-full object-scale-down object-right"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};
