import React, { useEffect, useRef } from "react";
import { useLayoutContext } from "../context/LayoutContext";

import {
  Domain,
  HeroSection,
  HowItWorks,
  NumberOneTech,
  // RecentProjects,
  Testimonial,
  Webpackage,
} from "../components/Home";

import { TechnologyWeUse } from "../components/Global/TechnologyWeUse";
import useGet from "../api/useGet";
import MetaHelmet from "../components/Global/MetaHelmet";
export const Home = () => {
  const { data } = useGet("static-home");
  const { data: meta } = useGet("seo-setting");
  const sectionRef = useRef(null);
  const { updateHeader, headerBg } = useLayoutContext();

  useEffect(() => {
    if (headerBg) {
      updateHeader(false);
    }
  }, [updateHeader, headerBg]);

  return (
    <>
      <MetaHelmet
        title={meta?.meta_title ? meta?.meta_title : 'Silicon Everest'}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <HeroSection data={data} sectionRef={sectionRef} />
      <TechnologyWeUse sectionRef={sectionRef} center={false} />
      <NumberOneTech data={data} />
      <HowItWorks />
      {/* <RecentProjects /> */}
      <Domain />
      <Testimonial data={data?.testimonial_description} />
    </>
  );
};
