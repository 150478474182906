import { AllRoutes } from "./routes/AllRoutes";
import { Header } from "./components/Global/Header";
import { Footer } from "./components/Global/Footer";
import "./App.css";
import { Sticky } from "./components/Global/Sticky";
import { HelmetProvider } from "react-helmet-async";

function App() {

  return (
    <>
      <HelmetProvider>
        <Sticky />
        <Header />
        <AllRoutes />
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default App;
