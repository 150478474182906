import { createContext, useContext, useReducer } from "react";
import { LayoutReducer } from "../reducer/LayoutReducer";

const initialState = {
  headerBg: false,
  productId: "",
};
const LayoutContext = createContext(initialState);
export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LayoutReducer, initialState);

  const updateHeader = (bg) => {
    dispatch({ type: "UPDATE_HEADER_BG", payload: { headerBg: bg } });
  };

  const updateProductId = ({ prodId }) => {
    dispatch({
      type: "UPDATE_PRODUCT_ID",
      payload: { productId: prodId },
    });
  };
  const value = {
    headerBg: state.headerBg,
    productId: state.productId,
    updateProductId,
    updateHeader,
  };
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
