import React from "react";
import styles from "../../Mobile.module.css";

export const Mobile = ({ image }) => {
  return (
    <div className={styles.mobile}>
      <div className={styles.mobile__camera}></div>
      <div className={styles.mobile__screen}>
        <img src={image} alt="Mobile Screen" />
      </div>
    </div>
  );
};
