export const navData = [
  {
    title: "About Us",
    link: "/about",
  },
  { title: "Products", link: "/products" },
  {
    title: "Portfolio",
    link: "/portfolio",
  },
  { title: "Services", link: "/services" },
  { title: "Domain & Hosting", link: "/domain" },
  {
    title: "Website Packages",
    link: "/webpackages",
  },
  { title: "Contact", link: "/contact" },
];
