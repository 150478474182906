import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaUserAlt } from "react-icons/fa";
import {
  MdPhone,
  MdOutlineMailOutline,
  MdOutlinePhoneInTalk,
  MdOutlineSubject,
} from "react-icons/md";

import { TbMessage2 } from "react-icons/tb";
import { IoMailOpenOutline, IoGlobeOutline } from "react-icons/io5";
import { LuBuilding } from "react-icons/lu";
import { useLayoutContext } from "../context/LayoutContext";
import MetaHelmet from "../components/Global/MetaHelmet";
import useGet from "../api/useGet";
import { Link } from "react-router-dom";
import UsePost from "../api/UsePost";

export const Contact = () => {
  const { updateHeader, headerBg } = useLayoutContext();
  const { data: meta } = useGet("seo-setting");
  const { data: setting } = useGet("site-setting");
  const { post } = UsePost("contacts");
  console.log(setting)

  const [loader, setLoader] = useState(false);
  const contactData = [
    {
      icon: <IoMailOpenOutline />,
      name: setting?.email,
      link: `mailto:${setting?.email}`,
    },
    {
      icon: <MdOutlinePhoneInTalk />,
      name: setting?.phone,
      link: `tel:${setting?.phone}`,
    },
    {
      icon: <IoGlobeOutline />,
      name: "www.siliconeverest.com",
      link: "http://siliconeverest.com/",
    },
    {
      icon: <LuBuilding />,
      name: setting?.contact_address,
      link: "#!",
    },
  ];
  useEffect(() => {
    if (headerBg === false) {
      updateHeader(true);
    }
  }, [updateHeader, headerBg]);
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    title: "",
    remarks: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.number().positive().min(10),
    email: Yup.string()
      .email("Invalid email format")
      .required("A email is required"),
    title: Yup.string().required("Subject is required"),
    remarks: Yup.string(),
  });
  const handleSubmit = async (values, { resetForm }) => {
    setLoader(true)
    await post(values, "contact");
    setLoader(false)
    resetForm();
  };
  return (
    <>
      <MetaHelmet
        title={`Contact | ${
          meta?.meta_title ? meta?.meta_title : "Silicon Everest"
        }`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />

      <section className="contact-page ">
        <div
          className={
            "contact-wrapper  lg:relative  lg:after:absolute  after:content-[''] after:inset-y-0 after:w-1/3 after:bg-primary after:right-0 after:z-[-1]"
          }
        >
          <div className="max-w-[1200px] container mx-auto   py-[80px] ">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
              <div className="col-span-1">
                <div className="contact-form-wrapper container mx-auto">
                  <div className="heading-wrapper pb-9">
                    <h4 className="text-secondary text-5xl font-semibold mb-2">
                      Contact us
                    </h4>
                    <p className="text-secondary text-opacity-50 font-medium ">
                      Feel free to contact us anytime. We will get back to you
                      as soon as possible
                    </p>
                  </div>
                  <div className="contact-form">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) =>
                        handleSubmit(values, { resetForm })
                      }
                    >
                      {(formik) => (
                        <Form onSubmit={formik.handleSubmit}>
                          <div className="grid grid-cols-2 gap-6">
                            <div className="col-span-full">
                              <div className="input-group relative">
                                <span className="input-icon pointer-events-none absolute top-[1px] left-0 flex items-center h-[calc(100%-2px)] border-r w-[36px] border-black border-opacity-20 border-solid justify-center">
                                  <FaUserAlt />
                                </span>
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Name"
                                  className={`w-full border border-black border-opacity-20 border-solid pl-11 py-[8px] pr-4 outline-0 focus:border-secondary hover:border-secondary transition-all duration-500 bg-[#F0F0F0] ${
                                    formik.errors.name && formik.touched.name
                                      ? "border-red-500 !border-opacity-100"
                                      : ""
                                  }`}
                                />
                              </div>
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-red-500  text-sm"
                              />
                            </div>
                            <div className="col-span-1">
                              <div className="input-group relative">
                                <span className="input-icon pointer-events-none absolute top-[1px] left-0 flex items-center h-[calc(100%-2px)] border-r w-[36px] border-black border-opacity-20 border-solid justify-center">
                                  <MdPhone />
                                </span>
                                <Field
                                  type="text"
                                  name="phone"
                                  placeholder="Phone"
                                  className={`w-full border border-black border-opacity-20 border-solid pl-11 py-[8px] pr-4  outline-0 focus:border-secondary hover:border-secondary transition-all duration-500 bg-[#F0F0F0] ${
                                    formik.errors.phone && formik.touched.phone
                                      ? "border-red-500 !border-opacity-100"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                            <div className="col-span-1">
                              <div className="input-group relative">
                                <span className="input-icon pointer-events-none absolute top-[1px] left-0 flex items-center h-[calc(100%-2px)] border-r w-[36px] border-black border-opacity-20 border-solid justify-center">
                                  <MdOutlineMailOutline />
                                </span>
                                <Field
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  className={`w-full border border-black border-opacity-20 border-solid pl-11 py-[8px] pr-4 outline-0 focus:border-secondary hover:border-secondary transition-all duration-500 bg-[#F0F0F0] ${
                                    formik.errors.email && formik.touched.email
                                      ? "border-red-500 !border-opacity-100"
                                      : ""
                                  }`}
                                />
                              </div>
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </div>
                            <div className="col-span-full">
                              <div className="input-group relative">
                                <span className="input-icon pointer-events-none absolute top-[1px] left-0 flex items-center h-[calc(100%-2px)] border-r w-[36px] border-black border-opacity-20 border-solid justify-center">
                                  <MdOutlineSubject />
                                </span>
                                <Field
                                  type="text"
                                  name="title"
                                  placeholder="Subject"
                                  className={`w-full border border-black border-opacity-20 border-solid pl-11 py-[8px] pr-4 outline-0 focus:border-secondary hover:border-secondary transition-all duration-500 bg-[#F0F0F0] ${
                                    formik.errors.title && formik.touched.title
                                      ? "border-red-500 !border-opacity-100"
                                      : ""
                                  }`}
                                />
                              </div>
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="text-red-500 text-sm"
                              />
                            </div>
                            <div className="col-span-full">
                              <div className="input-group relative flex">
                                <span className="input-icon pointer-events-none absolute top-[1px] left-0 flex items-center h-[calc(100%-2px)] border-r w-[36px] border-black border-opacity-20 border-solid justify-center">
                                  <TbMessage2 />
                                </span>
                                <Field
                                  as="textarea"
                                  type="text"
                                  rows="4"
                                  name="remarks"
                                  placeholder="Type your message here"
                                  className={`w-full border border-black border-opacity-20 border-solid pl-11 py-[8px] pr-4 bg-[#F0F0F0] outline-0 focus:border-secondary hover:border-secondary transition-all duration-500 ${
                                    formik.errors.remarks &&
                                    formik.touched.remarks
                                      ? "border-red-500 !border-opacity-100"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="btn-wrapper pt-6">
                            <button
                              type="submit"
                              className={
                                "inline-block px-8 py-2 uppercase text-white bg-secondary hover:opacity-90"
                              }
                            >
                              {loader ? "Submitting..." : "Submit"}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="contact-info bg-secondary h-full p-8 relative">
                  <div className="heaing-wrapper pb-6">
                    <h6 className="text-white text-2xl underline">
                      Silicon Everest
                    </h6>
                  </div>
                  <ul className="text-txtsecondary text-opacity-50">
                    {contactData.map((item, index) => (
                      <li
                        key={index}
                        className="flex gap-2 items-center [&:not(:last-child)]:mb-6 relative pl-8"
                      >
                        <span className="w-[50px] absolute top-0 left-0 text-2xl">
                          {item?.icon}
                        </span>
                        <Link to={item?.link} className="hover:underline">
                          {item?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <figure className="absolute bottom-4 right-4">
                    <img
                      src="/assets/images/icon/messages.svg"
                      alt="messages"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="map-wrapper h-[700px] ">
          <iframe
            title="map"
            src={setting?.map_url}
            className="border-0 w-full h-full"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </section>
    </>
  );
};
