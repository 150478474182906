import React, { useEffect } from "react";
import { useLayoutContext } from "../context/LayoutContext";
import { HeaderWidthCalculator } from "../components/Global/HeaderWidthCalculator";
import { PackageBox } from "../components/Global/PackageBox";
import useGet from "../api/useGet";
import HtmlParse from "../components/Global/HtmlParse";
import MetaHelmet from "../components/Global/MetaHelmet";

export const Webpackages = () => {
  const { data } = useGet("static-webpackage");
  const { data: why } = useGet("whyuses");
  const { data: whyInfo } = useGet("static-about");
  const { data: websitePackages } = useGet("website-packages");
  const { data: meta } = useGet("seo-setting");
  const { updateHeader, headerBg } = useLayoutContext();

  useEffect(() => {
    if (headerBg === false) {
      updateHeader(true);
    }
  }, [updateHeader, headerBg]);
  return (
    <>
      <MetaHelmet
        title={`Website Packages | ${
          meta?.meta_title ? meta?.meta_title : "Silicon Everest"
        }`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <section>
        <div className="bg-[#F5F5F5]">
          <div className="container mx-auto ">
            <div className="md:flex flex-col justify-center items-center  pt-16">
              <div className="">
                <HeaderWidthCalculator
                  title={"Find The Right Plan For Your Website"}
                />
              </div>

              <div className="text-center py-4 lg:w-1/2">
                {data?.website_description && <HtmlParse data={data?.website_description}/>}
              </div>
            </div>
          </div>

          <div className="package-group grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-14 max-w-[1200px]  mx-auto py-8">
            {websitePackages?.map((item, index) => (
              <div className="col-span-1 " key={item?.id}>
                <PackageBox {...item}></PackageBox>
              </div>
            ))}
          </div>
        </div>
        <div className=" pb-80" id="testimonial_bg">
          <div className="container mx-auto">
            <div className="flex flex-col justify-center items-center py-8 ">
              <h1 className="text-3xl font-semibold">Why Choose Us</h1>
              <p className="text-center lg:w-1/2 mt-4">
                {whyInfo?.why_choose_us && (
                  <HtmlParse data={whyInfo?.why_choose_us} />
                )}
              </p>
            </div>
            <div className="flex justify-center">
              <div className="grid lg:grid-cols-2 grid-cols-1 lg:w-3/4 gap-10">
                {why?.map((item, index) => (
                  <div className="col-span-1" key={item?.id}>
                    <div className="flex">
                      <div className="h-14 w-14 flex-none">
                        <img
                          src={item?.image}
                          alt=""
                          className="h-full w-full object-contain object-center"
                        />
                      </div>
                      <div className="px-3">
                        <h1 className="font-semibold">{item?.name}</h1>
                        <div className="text-justify ">
                          {item?.description && (
                            <HtmlParse data={item?.description} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
