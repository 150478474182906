export const LayoutReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_HEADER_BG":
      return {
        ...state,
        headerBg: payload.headerBg,
      };
    case "UPDATE_PRODUCT_ID":
      return {
        ...state,
        productId: payload.productId,
      };
    default:
      throw new Error(`Unknown Action Type ${type}`);
  }
};
