import React from "react";

import { HeaderWidthCalculator } from "../Global/HeaderWidthCalculator";

import ServiceBanner from "../../assets/img/servicesBanner.jpeg";
import ErpBanner from "../../assets/img/ERPBanner.jpeg";

import { paraDescription } from "../../data/Home/NumberOneTechCompany";
import { useNavigate } from "react-router";
import HtmlParse from "../Global/HtmlParse";
import useGet from "../../api/useGet";
import Loader from "../Global/Loader";
import Logo from "../../assets/images/comp_logo.svg";

export const NumberOneTech = ({ data }) => {
  const { data: services, isLoading: serviceLoading } = useGet("services");
  const { data: erps, isLoading: erpsLoading } = useGet("erps");
  const navigate = useNavigate();
  return (
    <>
      {serviceLoading && erpsLoading ? (
        <Loader />
      ) : (
        <section className="bg-secondary lg:mt-4">
          <div className="container mx-auto">
            <div className="grid lg:grid-cols-3 grid-cols-1 ">
              <div className="col-span-2  pt-8 lg:w-[80%]">
                <div className="container mx-auto ">
                  <div className="inline-block py-8">
                    <HeaderWidthCalculator
                      title={"Our Services"}
                      color={"white"}
                    />
                  </div>
                  <div className="grid lg:grid-cols-3  grid-cols-2  gap-8 ">
                    {services?.data_status?.slice(0, 6).map((item, index) => (
                      <div
                        className="col-span-1 bg-lineColor  pt-10 pb-4 rounded-lg "
                        key={index}
                      >
                        <div className="flex flex-col justify-center items-center  ">
                          <div className="h-12  w-12 ">
                            <img
                              src={item?.icon || Logo}
                              className="w-full h-full object-contain object-center"
                              alt=""
                              loading="lazy"
                            />
                          </div>

                          <p className="text-white text-center">{item?.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="btn-wrapper text-center pt-10">
                    <button
                      className="text-white border border-white px-6 py-2"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate("/services");
                      }}
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 ">
                <div className=" bg-primary h-full w-3/4 relative inset-x-0 bottom-0 top-[-24px] ">
                  <div className="hidden lg:block absolute   left-[-25%] top-[15%] w-[95%] xl:h-[90%] lg:h-[70%]">
                    <img
                      src={data?.service_image || ServiceBanner}
                      className="w-full h-full xl:object-fill object-center"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2 w-[85%] pt-12 ">
                <div className="flex gap-8 items-center">
                  <div className="bg-lineColor h-4 rounded-full w-4"></div>
                  <div className="bg-lineColor h-3 rounded w-full"></div>
                  <div className=" bg-lineColor h-3  rounded w-8"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto py-8">
            <div className="grid lg:grid-cols-3 grid-cols-1">
              <div className="col-span-1 ">
                <div className="bg-lineColor w-3/4 h-full relative inset-x-0 bottom-0">
                  <div className="absolute hidden lg:block  left-16 xl:top-16 lg:top-36  w-[90%] xl:h-[90%] lg:h-[50%]  ">
                    <img
                      src={data?.erp_image || ErpBanner}
                      className=" w-full h-full xl:object-contain object-center"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="container m-auto ">
                  <div className=" text-3xl pt-8">
                    <span className="text-white">
                      Enterprise Resource Planning-
                    </span>
                    <span className="text-primary">ERP</span>
                  </div>

                  <div className="">
                    <div className="text-txtsecondary text-sm pt-2 text-justify [&>p]:leading-6">
                      {data?.epr_description ? (
                        <HtmlParse data={data?.epr_description} />
                      ) : (
                        paraDescription
                      )}
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-10 py-8">
                      {erps?.slice(0,4).map((item, index) => (
                        <div className="col-span-1" key={index}>
                          <div className="container mx-auto ">
                            <div className="flex  ">
                              <div className="h-10 w-10 flex-none">
                                <img
                                  src={item?.image}
                                  alt=""
                                  className="w-full h-full object-contain object-center"
                                />
                              </div>

                              <div className=" px-4">
                                <h1 className="text-white font-bold ">
                                  {item?.name}
                                </h1>
                                <div className="text-txtsecondary text-justify [&>p]:line-clamp-5">
                                  {item?.description && (
                                    <HtmlParse data={item?.description} />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
