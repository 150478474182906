import React from "react";

import { useNavigate } from "react-router-dom";
import { GoArrowDown } from "react-icons/go";
import { FaLocationArrow } from "react-icons/fa";

import { HeaderColor } from "../Global/HeaderColor";
import { CustomButton } from "../Global/CustomButton";
import { Pc } from "../Global/Pc";

import { heroSectionTitle, heroSectionDesc } from "../../data/Home/HeroSection";
import HomePcBg from "../../assets/img/homeLaptopBg.jpg";
import { SocialMedia } from "../Global/SocialMedia";
import HtmlParse from "../Global/HtmlParse";

export const HeroSection = ({ data, sectionRef }) => {
  const navigate = useNavigate();
  return (
    <section className={`home_img 2xl:!bg-cover`}>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
          <div className="col-span-1 ">
            <div className="container m-auto gap-6 flex flex-col  ">
              <div className="">
                <h1 className="text-txtprimary text-3xl font-semibold">
                  Silicon Everest
                </h1>
                <HeaderColor title={data?.banner_title || heroSectionTitle} />
                <div className="text-txtsecondary py-8">
                  {data?.banner_description ? <HtmlParse data={data?.banner_description}/> : heroSectionDesc}
                </div>
              </div>
              <CustomButton
                title={"REACH OUT"}
                icon="form"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/contact");
                }}
              />
              <div className="flex flex-col gap-y-14">
                <SocialMedia color={"white"} />
                <div className="btn-wrapper  ">
                  <button
                    className="text-white border rounded-full inline-block py-4 px-2"
                    onClick={() =>
                      sectionRef.current.scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    <GoArrowDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 hidden lg:block">
            <div className="container mx-auto h-full  relative">
              <div className="bg-lineColor rounded-full max-w-52 flex justify-center absolute top-[65%] left-[-24px] bounce-top">
                <p className="text-txtprimary py-2 px-4 ">24 Hour Support</p>
              </div>
              <div className="bg-lineColor rounded-full max-w-52 flex justify-center absolute right-0 top-11 bounce-top">
                <p className="text-txtprimary py-2 px-6 ">Worldclass ERP</p>
              </div>
              <div className="bg-lineColor rounded-full max-w-52 flex justify-center absolute top-[60px] left-0 bounce-top">
                <p className="text-txtprimary py-2 px-6">Beautiful Websites</p>
              </div>
              <div className="absolute top-1/3 left-1/3">
                <Pc maxWidth={"20rem"} image={data?.banner_image ||HomePcBg} />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-primary rounded-full p-6  "
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/contact");
                }}
              >
                <FaLocationArrow />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
