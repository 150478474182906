import React, { useEffect } from "react";

import { useLayoutContext } from "../context/LayoutContext";

import { HeroSection } from "../components/Products/HeroSection";
import { TechnologyWeUse } from "../components/Global/TechnologyWeUse";
import { OurProduct } from "../components/Products/OurProduct";
import MetaHelmet from "../components/Global/MetaHelmet";
import useGet from "../api/useGet";

export const Products = () => {
  const { updateHeader, headerBg } = useLayoutContext();
  const { data: meta } = useGet("seo-setting");

  useEffect(() => {
    if (headerBg === false) {
      updateHeader(true);
    }
  }, [updateHeader, headerBg]);

  return (
    <>
      <MetaHelmet
        title={`Products | ${
          meta?.meta_title ? meta?.meta_title : "Silicon Everest"
        }`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <HeroSection />
      <TechnologyWeUse center={true} />
      <OurProduct />
    </>
  );
};
