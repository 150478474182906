import React from "react";

import { CustomButton } from "../../components/Global/CustomButton";
import { Pc } from "../../components/Global/Pc";
import { Mobile } from "../../components/Global/Mobile";

import { listOfProducts } from "../../data/Products/ourProducts";
import useGet from "../../api/useGet";
import HtmlParse from "../Global/HtmlParse";
export const OurProduct = () => {
  const { data } = useGet("products");
  return (
    <section className="lg:mt-32 pb-80">
      {data?.map((item, index) => (
        <React.Fragment key={index}>
          <OurProductCard
            title={item?.name}
            desc={item?.description && <HtmlParse data={item?.description}/>}
            image={item?.main_image}
            type={"PC"}
            bg={index % 2 === 0 ? "secondary" : "white"}
            index={index}
          />
        </React.Fragment>
      ))}
      {listOfProducts.length % 2 === 0 && (
        <div className="">
          <img
            src="/assets/images/bg/product_last_bg.svg"
            className="w-full"
            alt=""
          />
        </div>
      )}
    </section>
  );
};
export const OurProductCard = ({
  title,
  desc,
  image,
  type,

  bg,
  index,
}) => {
  return (
    <div className={` relative `}>
      <div className={`${bg === "white" ? "pb-12" : "bg-secondary"}`}>
        <div className={`container mx-auto `}>
          <div className="grid lg:grid-cols-2 grid-cols-1 ">
            <div
              className={
                bg === "white"
                  ? `col-span-1 relative lg:order-1  lg:min-h-[400px] min-h-[250px]`
                  : `col-span-1 relative lg:-order-1  lg:min-h-[400px] min-h-[250px]`
              }
            >
              <div
                className={
                  index === 1
                    ? "lg:absolute -top-52 inset-x-0 xl:pt-0 lg:pt-14 pt-10 pb-14  lg:pb-0"
                    : "lg:absolute -top-20 inset-x-0 pt-10 pb-14 lg:pt-0 lg:pb-0"
                }
              >
                {type === "PC" ? (
                  <Pc image={image} maxWidth={"20rem"} />
                ) : (
                  <Mobile image={image} />
                )}
              </div>
            </div>
            <div
              className={
                bg === "secondary"
                  ? "col-span-1 lg:max-w-3/4 self-center"
                  : "col-span-1 lg:ml-[25%] self-center"
              }
            >
              <div className="flex flex-col">
                <h1
                  className={
                    bg === "white"
                      ? "text-2xl font-bold"
                      : "text-2xl font-bold text-white"
                  }
                >
                  {title}
                </h1>
                <p className={bg === "white" ? "" : " text-txtsecondary "}>
                  {desc}
                </p>
                <div className="py-8">
                  {" "}
                  <CustomButton
                    title={"View Details"}
                    icon="right-arrow"
                    trailing={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {bg === "secondary" && index === 0 && (
        <div className="img-bottom lg:block hidden">
          <img src="/assets/images/bg/bottom.png" className="w-full " alt="" />
        </div>
      )}
    </div>
  );
};
