import React, { useEffect } from "react";

import { useLayoutContext } from "../context/LayoutContext";

import { HeroSection } from "../components/Portfolio/HeroSection";
// import { ProjectDetail } from "../components/Portfolio/ProjectDetail";
import { Projects } from "../components/Portfolio/Projects";
import { Clients } from "../components/Portfolio/Clients";
import MetaHelmet from "../components/Global/MetaHelmet";
import useGet from "../api/useGet";

export const Portfolio = () => {
  const { updateHeader, headerBg } = useLayoutContext();
  const { data: meta } = useGet("seo-setting");
  const { data:projects } = useGet("projects");

  useEffect(() => {
    if (headerBg === false) {
      updateHeader(true);
    }
  }, [updateHeader, headerBg]);

  return (
    <>
      <MetaHelmet
        title={`Portfolio | ${
          meta?.meta_title ? meta?.meta_title : "Silicon Everest"
        }`}
        keyword={meta?.meta_keywords}
        description={meta?.meta_description}
      />
      <HeroSection />
      <Projects data={projects} />
      <Clients data={projects}/>
    </>
  );
};
