import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { Link } from "react-router-dom";

const SearchField = ({ data, setkeyword, name,setPostData }) => {
  const [selected, setSelected] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const highlightedItemRef = useRef(null);

  const handleClickOutside = useCallback(() => {
    if (!data?.some((item) => item.title === selected)) {
      setSelected("");
      setPostData([])
      setHighlightedIndex(-1);
    }
    setShowDropdown(false);
  }, [selected, data]);
  useClickOutside(dropdownRef, handleClickOutside);
console.log(highlightedIndex)
  //keyboard friendly
  const handleKeyDown = useCallback(
    (e) => {
      const { key } = e;
      let updateNeeded = false;
      let newIndex = highlightedIndex;
      let newShowDropdown = showDropdown;
      let newCompareSelected = selected;

      if (data?.length > 0) {
        switch (key) {
          case "ArrowDown":
            e.preventDefault();
            newIndex =
              highlightedIndex < data.length - 1 ? highlightedIndex + 1 : 0;
            updateNeeded = true;
            break;
          case "ArrowUp":
            e.preventDefault();
            newIndex =
              highlightedIndex > 0 ? highlightedIndex - 1 : data.length - 1;
            updateNeeded = true;
            break;
          case "Enter":
            if (highlightedIndex >= 0) {
              const selectedItem = data[highlightedIndex];
              newCompareSelected = selectedItem.title;
              newShowDropdown = false;
              updateNeeded = true;
            }
            break;
          case "Escape":
            newCompareSelected = "";
            newIndex = 0;
            newShowDropdown = false;
            setPostData([])
            updateNeeded = true;
            break;
          default:
            break;
        }
      } else {
        switch (key) {
          case "Enter":
          case "Escape":
            newCompareSelected = "";
            newShowDropdown = true;
            newIndex = -1;
            updateNeeded = true;
            break;
          default:
            break;
        }
      }

      if (updateNeeded) {
        setHighlightedIndex(newIndex);
        setSelected(newCompareSelected);
        setShowDropdown(newShowDropdown);
      }
    },
    [highlightedIndex, data, showDropdown, selected]
  );

  //keyboard friendly

  const scrollToHighlightedItem = useCallback(() => {
    if (highlightedIndex >= 0 && data?.length > 0) {
      const dropdown = listRef.current;
      if (dropdown) {
        const highlightedItem = dropdown.children[highlightedIndex];
        const dropdownRect = dropdown.getBoundingClientRect();
        const highlightedItemRect = highlightedItem.getBoundingClientRect();

        if (highlightedItemRect.bottom > dropdownRect.bottom) {
          dropdown.scrollTop +=
            highlightedItemRect.bottom - dropdownRect.bottom;
        } else if (highlightedItemRect.top < dropdownRect.top) {
          dropdown.scrollTop -= dropdownRect.top - highlightedItemRect.top;
        }
      }
    }
  }, [highlightedIndex]);

  useEffect(() => {
    if (showDropdown) {
      inputRef.current.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    scrollToHighlightedItem();
  }, [highlightedIndex, scrollToHighlightedItem]);
  return (
    <>
      <div className="searchable-field relative flex-grow" ref={dropdownRef}>
        <input
          type="text"
          name={name}
          ref={inputRef}
          onChange={(e) => {
            setSelected(e.target.value);
            setkeyword(e.target.value);
            setShowDropdown(true);
          }}
          onClick={() => {
            setShowDropdown(true);
            if (data?.length > 0) {
              setHighlightedIndex(0);
            }
          }}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          value={selected}
          className=" w-full border border-searchbarborder  bg-searchbar rounded-3xl p-2"
        />
        {showDropdown && (
          <ul
            ref={listRef}
            className="search-data absolute bg-[#e3e3e3] text-[#000] w-full"
          >
            {data?.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  setSelected(item?.title);
              
                  setShowDropdown(false);
                }}
                className={`cursor-pointer bg-[#e3e3e3] duration-300 ${
                  highlightedIndex === index ? "bg-black bg-opacity-30" : ""
                }`}
                onMouseEnter={() => setHighlightedIndex(index)}
                ref={highlightedIndex === index ? highlightedItemRef : null}
              >
                <Link
                  to={'products'}
                  className="block px-4 py-3 "
                >
                  {item?.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};
const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};
export default SearchField;
