import { listOfProducts } from "../Products/ourProducts";
import { navData } from "../Global/Header";

const copyListOfProducts = [...listOfProducts];
const copyNavData = [...navData];
const copyOfNavData = [...navData];

export const footerData = [
  {
    title: "Product",
    list: copyListOfProducts,
  },
  { title: "Quick Links", list: copyOfNavData.splice(0, 4) },
  { title: "Quick Links", list: copyNavData.splice(4) },
];
