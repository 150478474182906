// import { CustomButton } from "../../components/Global/CustomButton";
import { Pc } from "../../components/Global/Pc";
import HtmlParse from "../Global/HtmlParse";
// import { RegionPlatformCard } from "./RegionPlatformCard";
// import { useLayoutContext } from "../../context/LayoutContext";

export const ProjectCard = ({ name, description, main_image, index, logo }) => {
  // const { updateProductId } = useLayoutContext();

  return (
    <div className={index % 2 === 0 ? " " : " bg-[#DEDEDE]"}>
      <div className="container mx-auto">
        <div className="py-16">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
            <div
              className={
                index % 2 === 0
                  ? "col-span-1 lg:-order-1 min-h-96"
                  : "col-span-1 lg:order-1 min-h-96"
              }
            >
              <div
                className={`bg-gradient-to-t from-primary to-gray-400 min-h-full  flex flex-col justify-center items-center relative  `}
              >
                <div className="w-full ">
                  <Pc image={main_image} maxWidth={"17rem"} />
                </div>
                <div className="w-full px-14 absolute top-[85%]">
                  <h1 className="font-semibold text-xl text-center">{name}</h1>
                </div>
              </div>
            </div>

            <div className="col-span-1">
              <div className="flex flex-col ">
                <div className="flex justify-center items-center shadow-md lg:w-72 lg:h-24 w-48 h-14 bg-white rounded ">
                  <img
                    src={logo}
                    className="max-h-full max-w-full  object-contain object-center"
                    alt=""
                  />
                </div>

                <div className="py-4">
                  <h1 className="font-bold text-xl">{name}</h1>
                  <div className="[&>p]:line-clamp-3">{description && <HtmlParse data={description}/>}</div>
                </div>
                {/* <RegionPlatformCard regions={regions} platforms={platforms} /> */}
                {/* <div className="py-8"> */}
                {/* <CustomButton
                    title={"View Details"}
                    trailing={true}
                    icon="right-arrow"
                    onClick={() => {
                      document.body.classList.add("overflow-hidden");
                      document.body.classList.remove("overflow-scroll");
                      updateProductId({ prodId: id });
                    }}
                  /> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
